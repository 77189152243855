/* eslint-disable */
// NOTE :: DO NOT EDIT :: this file is automatically generated by generate-environment-variables.js node script

export const envVariables: { [key: string]: string } = {
  CIRCLE_TAG: 'prod-purposeful-v117',
  PROJECT: 'purposeful',
};
export const latestTag: string = 'prod-purposeful-v117';
export const latestVersion: string = 'v117';
export const versions: { app: { [key: string]: string }, branch: string, npm_version: string, revision: string } = {
  app: {
    purposeful: '6.4.2',
    insightful: '2.8.0',
  },
  branch: 'HEAD',
  npm_version: '156.0.0',
  revision: '49f6dbd2e',
};
/* eslint-enable */
